import React, { useEffect, useState } from "react"

import { navigate } from "../../services/routes" // "gatsby"
import { logout } from "../../services/auth"
import routes from "../../services/routes"

export default () => {

	const [state, setState] = useState("start");

	const finish = () => {
		navigate(routes.login);
	}

	useEffect(() => {
		if (state==="start") {
			setState("logging out");
			(async () => {
				await logout();
				setState("logged out");
				setTimeout(finish, 5000);
			})();
		}
	});

	return (
		<>
			<section className="banner_area" style={{minHeight:"200px"}}>
				<div className="banner_inner d-flex align-items-center" style={{minHeight:"200px"}}>
					<div className="container">
						<div className="banner_content text-center">
							<h2>Log Out</h2>
						</div>
					</div>
				</div>
			</section>
			{ (state === "start" || state === "logging out") &&
				<div style={{backgroundColor:"white", margin:"20px", padding:"5px", textAlign:"center"}}>
					<h4> </h4>You are being logged out...
				</div>
			}
			{ (state === "logged out") &&
				<div style={{backgroundColor:"white", margin:"20px", padding:"5px", textAlign:"center"}}>
					<h4> </h4>You have been logged out.
				</div>
			}
		</>
	);
};